.aida {
    margin: 30px;
    border-radius: 10px;
}

.text-center {
    text-align: center
}

p {
    font-size: 1.3em;
}

.amazon-badge {
    margin-top: 20px;
}

.apresentacao p {
    font-size: 15px;
    text-align: justify;
    text-justify: inter-word;
}

.itonome {
    margin-bottom: 50px;
    font-weight: bold
}

.apresenta {
    text-align: justify;
    text-justify: inter-word;
}

.main-menu {
    margin-top: 20px;
}
a:link {color: #39804f;}
a:active {color: #39804f;}
a:visited {color: #39804f;}
a:hover {color: #39804f;}
.header-main {
    /* padding: 50px; */
    margin-top: 50px;
    text-align: center;
}

.avatar-picture {
    margin-bottom: 20px;
}

.text-unit {
    text-align: center;
    font-size: 20px;
}

.text-unit.text-name {
    text-align: center;
    font-size: 35px;
    color: #39804f;
}

.text-unit.title {
    font-weight: 200;
    color: #aaa;
    margin-top: 10px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    /* font: italic 19px/1.3em Georgia,serif; */
}

.separator {
    display: block;
    border: none;
    height: 1px;
    margin: 35px auto;
    background: #eee;
}

.separator {
    max-width: 800px;;
}
.social-icons {
    margin-top: 40px;
    text-align: center;
}

.social-icon {
    font-size: 32px;
    color: #cdd4da;
    /*  */
    margin-left:20px;
    margin-right:10px;
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-align: center;
    /*  */
    /* border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    margin: 0;
    padding: 0; */
    position: relative;
    top: 8px;
}

.copyright {
    margin-top: 20px;
    margin-bottom: 20px;
    margin: 20px auto;
    font-size: 10px;
}

.instagramicone {
    font-size: 14px;
}
.wrapper {
    max-width: 800px;
    margin: 0 auto;
    padding: 10px 40px 100px 40px;
}

.header-main {
    /* padding: 50px; */
    margin-top: 50px;
    text-align: center;
}

.avatar-picture {
    margin-bottom: 20px;
}

.text-unit {
    text-align: center;
    font-size: 20px;
}

.text-unit.text-name {
    text-align: center;
    font-size: 35px;
    color: #39804f;
}

.text-unit.title {
    font-weight: 200;
    color: #aaa;
    margin-top: 10px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    /* font: italic 19px/1.3em Georgia,serif; */
}

.separator {
    display: block;
    border: none;
    height: 1px;
    margin: 35px auto;
    background: #eee;
}

.separator {
    max-width: 800px;;
}
.social-icons {
    margin-top: 40px;
    text-align: center;
}

.social-icon {
    font-size: 32px;
    color: #cdd4da;
    /*  */
    margin-left:20px;
    margin-right:10px;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-align: center;
    /*  */
    /* border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    margin: 0;
    padding: 0; */
    position: relative;
    top: 8px;
}

.copyright {
    margin-top: 20px;
    margin-bottom: 20px;
    margin: 20px auto;
    font-size: 10px;
}

.instagramicone {
    font-size: 14px;
}
.aida {
    margin: 30px;
    border-radius: 10px;
}

.text-center {
    text-align: center
}

p {
    font-size: 1.3em;
}

.amazon-badge {
    margin-top: 20px;
}

.apresentacao p {
    font-size: 15px;
    text-align: justify;
    text-justify: inter-word;
}

.itonome {
    margin-bottom: 50px;
    font-weight: bold
}

.apresenta {
    text-align: justify;
    text-justify: inter-word;
}